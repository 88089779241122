@import url(https://fonts.googleapis.com/css2?family=Jura:wght@700&display=swap);
/* .item must be first */
.item {
    align-items: center;
    color: #1a97f5;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    height: 40px;
    justify-content: center;
    width: 40px;
}

.disabled-page {
    color: #808e9b;
}

.active {
    border: solid 3px #1a97f5;
    border-radius: 12px;
    color: #000;
}

.break-me {
}

.next {
    background-color: #1a97f5;
    font-size: 4px;
    height: 60px;
    position: absolute;
    right: 0;
    width: 60px;
    border-radius: 12px;
    color: white;
}

.pagination {
    align-items: center;
    background-color: white;
    display: flex;
    flex-direction: row;
    height: 60px;
    justify-content: center;
    list-style-type: none;
    position: relative;
    width: 1000px;
}

.pagination-page {
    font-weight: 700;
}

.previous {
    background-color: #1a97f5;
    font-size: 4px;
    height: 60px;
    left: 0;
    position: absolute;
    width: 60px;
    border-radius: 12px;
    cursor: pointer;
    color: white;
}

*{
    font-family: 'Jura', sans-serif;
}

